/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
button,
html,
body,
html a {
  /* font-family: 'Helvetica Neue', Helvetica, 'Roboto', sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

a,
button,
li,
span {
  -webkit-tap-highlight-color: transparent;
}

ul {
  margin: 0;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-track-x {
  height: 5px !important;
}

.scrollbar-track-y {
  width: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb-y {
  width: 5px !important;
}

.BetaBanner[class] {
    background: repeating-linear-gradient(
        315deg,
        yellow,
        yellow 10px,
        black 10px,
        black 20px
    );
    text-align: center;
    width: 100%;
    height: 40px;
}

.BetaBanner[class] span.BannerSpan[class] {
    width: initial !important;
    position: initial !important;
    padding-top: 0px !important;
    margin-top: 3px !important;
    padding: 7px !important;
    color: white;
    display: inline-block;
    width: initial;
    background-color: rgb(64, 64, 64);
    border-radius: 4px;
    text-shadow: 0px 0px 10px black;
    font-weight: bold;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* header {
  margin-top: 40px;
}

.MCContent {
  margin-top: 40px;
}

#BetaBanner {
  position: fixed;
  top: 0px;
  left: 0px;
}

#BetaBanner span {
  padding-top: 10px;
  position: absolute;
  top: 2px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
} */

.ReactModal__Content {
  outline: none;
}

.Modal {
  position: absolute;
  top: 180px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 16px;
  max-height: 300px;
  background-color: white;
  border-radius: 2px;
  animation: "bounceIn .5s ease-in-out";
  overflow:auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.Centered {
  text-align: center;
}

@media (max-width: 767px) {
  .layoutContentWrapper {
    padding-top: 12px;
  }
}

